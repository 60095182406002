
:root{
  --bgbody:#fdfcfc;
  --textcolor:#1D1D1D;
  --divcolor:#ffffff;
  --bubblebgcolor:#d8EEEC;
}

*{
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  user-select: none;
  overflow-x: hidden;
}

.App{
  background-color: var(--bgbody);
  transition: 0.3s ease;
  
}

html,
body,
#root{
  width: 100vw;
  height: 100vh;
}

