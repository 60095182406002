
@media screen and (orientation:landscape) {

    .bubble{
        background-color: var(--bubblebgcolor);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bubble-infos{
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    }


    .bubble-infos>h2{
        font-size: 1.6vh;
        font-weight: 500;
        color:var(--textcolor)
    }

    .bubble-infos>.teammate-position{
        font-size: 1.4vh;
        font-weight: 300;
        color: var(--textcolor);
    }

    .bubble-infos>h3{
        font-size: 1.1vh;
        font-weight: 300;
        color: var(--textcolor);
    }

    .bubble-infos>.music-player-container{
        width: 50%;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bubble-infos>.music-player-container>.next-button{
        height: 40%;
        max-width: 25%;
        transition: 0.3s;
    }

    .bubble-infos>.music-player-container>.next-button:hover{
        transform: scale(1.2);
        transition: 0.2s;
    }

    .bubble-infos>.music-player-container>.reverse:hover{
        transform: scale(1.2) rotate(180deg);
    }

    .bubble-infos>.music-player-container>.play-button{
        height: 90%;
        max-width: 45%;
        transition: 0.2s ease-in;
    }

    .bubble-infos>.music-player-container>.play-button:hover{
        transform: scale(0.8);
        transition: 0.2s ease-in;
    }

    .bubble-infos>.music-player-container>.reverse{
        transform: rotate(180deg);
    }

    .bubble-infos>.teammate-music-title{
        font-size: 0.9vh;
        font-weight: 500;
        color: var(--textcolor);
    }

    .bubble-infos>.teammate-music-title>span{
        font-weight: 300;
    }
    
}

@media screen and (orientation:portrait) {
    
    .bubble{
        background-color: var(--bubblebgcolor);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .bubble-infos{
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
    }


    .bubble-infos>h2{
        font-size: 1.6vw;
        font-weight: 500;
        color:var(--textcolor)
    }

    .bubble-infos>.teammate-position{
        font-size: 1.4vw;
        font-weight: 300;
        color: var(--textcolor);
    }

    .bubble-infos>h3{
        font-size: 1.1vw;
        font-weight: 300;
        color: var(--textcolor);
    }

    .bubble-infos>.music-player-container{
        width: 50%;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bubble-infos>.music-player-container>.next-button{
        height: 40%;
        max-width: 25%;
        transition: 0.3s;
    }

    .bubble-infos>.music-player-container>.next-button:hover{
        transform: scale(1.2);
        transition: 0.2s;
    }

    .bubble-infos>.music-player-container>.reverse:hover{
        transform: scale(1.2) rotate(180deg);
    }

    .bubble-infos>.music-player-container>.play-button{
        height: 90%;
        max-width: 45%;
        transition: 0.2s ease-in;
    }

    .bubble-infos>.music-player-container>.play-button:hover{
        transform: scale(0.8);
        transition: 0.2s ease-in;
    }

    .bubble-infos>.music-player-container>.reverse{
        transform: rotate(180deg);
    }

    .bubble-infos>.teammate-music-title{
        font-size: 0.9vw;
        font-weight: 500;
        color: var(--textcolor);
    }

    .bubble-infos>.teammate-music-title>span{
        font-weight: 300;
    }

}


