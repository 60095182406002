@media screen and (orientation:landscape) {
    
    .About{
        width: 100vw;
        height:fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .titleAbout{
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
    }
    .titleAbout>h1{
        font-size: 5vh;
        color: var(--textcolor);
    }

    .titleAbout>p{
        text-align: center;
        width: 60%;
        font-size: 2vh;
        color: var(--textcolor);
    }

    .titleAbout>h2{
        color: #4CB5AA;
        font-size: 3vh;
        font-weight: 500;
    }


    .bubbles-grid{
        width: 100%;
        height: 100vw;
        display: grid;
        grid-template: 5% 10% 25% 10% 10% 10% 1fr / 5% 5% 30% 5% 30% 10% 1fr;
    }


}


@media screen and (orientation:portrait) {
    
    .About{
        width: 100vw;
        height:fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .titleAbout{
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        
    }
    .titleAbout>h1{
        font-size: 5vw;
        color: var(--textcolor);
    }

    .titleAbout>p{
        text-align: center;
        width: 80%;
        font-size: 2vw;
        color: var(--textcolor);
    }

    .titleAbout>h2{
        color: #4CB5AA;
        font-size: 3vw;
        font-weight: 500;
    }


    .bubbles-grid{
        width: 100%;
        height: 100vw;
        display: grid;
        grid-template: 5% 10% 25% 10% 10% 10% 1fr / 5% 5% 30% 5% 30% 5% 1fr;
    }

    





}