@media screen and (orientation:landscape) {

    #hb-container{
        display: none;
    }

    .mobile-moon{
        display: none;
    }


    #Navbar{
        display: flex;
        justify-content: space-between;
        width: 100vw;
        height: 10vh;
    }

    .linklogo{
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .linklogo>img{
        height: 40%;
        transition: 0.3s;
    }

    .linklogo>img:hover{
        transform: scale(1.15);
        transition: 0.3s;   
    }

    #menu{
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        z-index: 2;
    }

    #menu a{
        text-decoration: none;
        padding: 5px 0;
        transition: 0.3s;
        font-size: 1.5vh;
        color: var(--textcolor);
    }

    #menu a:hover{
        transform: scale(1.15);
        transition: 0.3s;
    }

    

    #menu a.active{
        border-bottom:#4CB5AA 3px solid ;
        font-weight: 600;
    }

    #menu>img{
        height: 25%;
        transition: 0.3s;
    }

    #menu>img:hover{
        transform: scale(1.2);
        transition: 0.3s;
    }

}

@media screen and (orientation:portrait) {


    .desktop-moon{
        display: none;
    }

    #Navbar{
        display: flex;
        justify-content: space-between;
        padding: 0 5vw;
        align-items: center;
        width: 90vw;
        height: 10vh;
        overflow-y: hidden;
    }

    .linklogo{
        width: fit-content;
        height: 100%;
        display: flex;
        align-items: center;
        transition: 0.3s;
    }

    .linklogo img{
        height: 45%;
    }

    .linklogo:hover{
        transform: scale(1.15);
        transition: 0.3s; 
    }

    .mobile-moon{
        height: 30%;
        transition: 0.3s;
    }

    .mobile-moon:hover{
        transform: scale(1.2);
        transition: 0.3s;
    }

    #hb-container{
        width: 5vh;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        transition: 0.4s ease-in-out;
    }

    .hb-bar{
        width: 100%;
        height: 2px;
        background-color: var(--textcolor);
        
    }

    #hb-container.hb-active{
        transform: rotate(90deg);
        transition: 0.4s ease-in-out;
    }


    #menu{
        position: absolute;
        width: 100vw;
        height: 0;
        left: 0;
        top: 10vh;
        background-color: var(--bgbody);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        transition: 0.5s ease-in-out;
        z-index: 2;
    }

    #menu.menu-active{
        height: 20vh;
        transition: 0.5s ease-in-out;
        border-top: 4px solid #4CB5AA;
        border-bottom: 4px solid #4CB5AA;
        z-index: 2;
    }

    #menu a{
        text-decoration: none;
        font-size: 2.5vw;
        padding: 5px 0;
        transition: 0.3s;
        color: var(--textcolor);
    }

    #menu a:hover{
        transform: scale(1.15);
        transition: 0.3s;
    }

    #menu a.active{
        font-weight: 600;
        border-bottom: 2px solid #4CB5AA;
    }
}