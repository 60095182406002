.GeneratePlaylist{
    width: 100vw;
    height:250vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.Form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 90%;  
}

.Form>div:not(.wlcm-user-container,.submit-button-container){
    width: 90%;
    background-color: var(--divcolor);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 25px;
    padding: 3%;
}

.wlcm-user-container{
    width: 90%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.wlcm-user-container>h2{
    font-size: 3.5vh;
    color: var(--textcolor);
}

.wlcm-user-container>p{
    font-size: 2vh;
    color: var(--textcolor);
}

.wlcm-user-container>input{
    align-self: flex-end;
    color: var(--textcolor);
    background-color: transparent;
    border: #52B5AA 1px dashed;
    padding: 3px;
    width: fit-content;
    max-width: 40%;
}

.wlcm-user-container>h2>span{
    color: #52B5AA;
}



.parameters-container{
    height: 80vh;
}




.submit-button-container{
    height: 20vh;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button-container>button{
    width: 40%;
    height: 40%;
    background-color: #52B5AA;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    font-size: 2vh;
    transition: 0.4s ease-in;
}

.submit-button-container>button:hover{
    transform: scale(1.1);
    transition: 0.1s ease-in-out;
}

@media screen and (orientation:portrait) {


    .submit-button-container>button{
        width: 60%;
        height: 40%;
    } 


    .GeneratePlaylist{
        height: 350vh;
    }
}