.articlesgrid{
    width: 100vw;
    height: 90vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 5vw 1fr 5vw;
    grid-template-rows: 5vh 1fr;
}

.articlesgrid>div:not(.articlesContainer){
    grid-area: 1/1/3/4;
}

.articlesContainer{
    width: 80vw;
    height: 90vh;
    z-index: 1;
    grid-area: 2/2/3/3;
    display: flex;
    align-self: center;
    justify-self: center;
}


@media screen and (orientation:landscape) {
    
    .articlesContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .articlesLinks{
        width: 85%;
        height: 15%;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        
    }

    .articlesLinks>a{
        width: fit-content;
        line-height: 3rem;
        height: 90%;
        writing-mode: vertical-rl;
        text-align: center;
        background-color: var(--bgbody);
        border-radius: 10% 10% 0 0;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        text-decoration: none;
        color: var(--textcolor);
        transition: 0.3s ease;
        font-size: 1.7vh;
    }

    .articlesLinks>a.active{
        border: none;
        border-radius: 15% 15% 0 0;
        color: #45B5AA;
        padding:0 1% ;
        background-color: var(--divcolor);
        transition: 0.3s ease;
        z-index: 2;
        font-family: 'BioRhyme', serif;
    }

    .article{
        width: 80%;
        height: 60%;
        background-color: var(--divcolor);
        border-radius: 4% / 6%;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        padding: 5%;
        overflow: hidden;
    }

    .article h1{
        color: #45B5AA;
        font-family: 'BioRhyme', serif;
        margin-bottom: 2vh;
        font-size: 3vh;
    }

    .article p{
        color: var(--textcolor);
        font-size: 1.7vh;
    }

    .article p a, .article p a:visited{
        color: #45B5AA;
        text-decoration: none;
    }



}

@media screen and (orientation:portrait) {
    
    .articlesContainer{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .articlesLinks{
        height:80%;
        width:13%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-end;
        z-index: 2;
    }

    .articlesLinks>a{
        height: fit-content;
        line-height: 2.3rem;
        width:90%;
        text-align: center;
        background-color: var(--bgbody);
        border-radius: 10% 0 0 10%;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        text-decoration: none;
        color: var(--textcolor);
        transition: 0.3s ease;
    }

    .articlesLinks>a.active{
        border: none;
        border-radius: 10% 0 0 10%;
        color: #45B5AA;
        padding: 7% 0;
        background-color: var(--divcolor);
        transition: 0.3s ease;
    }

    .article{
        height: 80%;
        width:  74%;
        background-color: var(--divcolor);
        border-radius: 8% / 6%;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        padding: 5%;
        overflow: hidden;
    }

    .article h1{
        color: #45B5AA;
        font-family: 'BioRhyme', serif;
        margin-bottom: 2vh;
        font-size: 3.5vw;
    }

    .article p{
        color: var(--textcolor);
        font-size: 2.2vw;
    }

    .article p a, .article p a:visited{
        color: #45B5AA;
        text-decoration: none;
    }


}